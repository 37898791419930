.aauti-logo-container{
    display: flex;
    align-items: flex-end;
    /* border: 1px solid brown; */
}
.aauti-logo{
    /* width: 10vw; */
    height: 70px
}
.aauti-school-heading{
    font-size: 10px;
    color: #108ac9;
    font-weight: bold;
    margin:0
}
@media screen and (min-Width:400px) and (max-width:500px) {
    .aauti-logo{
        width: 180px;
    }
    .aauti-school-heading{
        font-size: 32px;
    }
}

@media screen and (min-Width:500px) and (max-width:599px) {
    .aauti-logo{
        width: 240px;
    }
    .aauti-school-heading{
        font-size: 38px;
    }
}

@media screen and (min-width:992px)  {
    .aauti-logo{
        width: 280px;
    }
    .aauti-s chool-heading{
        font-size: 26px;
    }
}

.Mui-focused.outLined .input-adornment{
    color:  #3166ba!important;

 
}

.Mui-focused.outLined .input-adornment .inputs{
    color:  #3166ba!important; 
}

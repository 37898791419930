/* .p {
    color: '#333';
    font-size: "12px";
    margin: 0;
    padding:0;
    background-color: tan;
} */

@media screen and (max-width:768px) {
.responsive-p {
        font-size: 12px;
        color: "#333";
        margin:0;
        padding-bottom: 2px;
        font-family: "HelveticaNeue-Medium",
        /* content: "Media query triggered!"; */
    }
}

@media screen and (min-width: 768px) and (max-width:1200px) {
    .responsive-p{
        font-size: 12px;
        color: "#333";
        margin:0;
        padding-bottom: 2px;
        font-family: "HelveticaNeue-Medium",
    }
}

@media screen and (min-width: 1200px) {
    .responsive-p {
        font-size: 14px;
        color: "#333";
        padding-bottom: 2px;
        font-family: "HelveticaNeue-Medium";
        margin:0;
    }
}
@media screen and (max-width:767px) {
  .responsive-modelHeight{
    height:66vh;
    margin-bottom: 40px;
  }
    }
    
    @media screen and (min-width: 768px) and (max-width:1200px) {
        .responsive-modelHeight{
            height:90vh;
            margin-bottom:20px;
            /* background-color:red; */
          }
    }
    
    @media screen and (min-width: 1200px) {
        .responsive-modelHeight{
            height:65vh;
            margin-bottom: 20px;
          }
    }


    /* @media screen and(min-width:360px) and (max-width:768px) { 
      .responsive-model{
        height:90vh;
        background-color: red;
      }
      }
      @media screen and (min-width: 769px) and (max-width:1200px) { 
       
      }
      @media screen and (min-width: 1200px){ 
    
       } */


       @media screen and (max-width:768px) {
        .responsive-errorMessage {
                font-size: 8px;
                color: red;
                margin:0;
                font-family: "HelveticaNeue-Medium",
            }
        }
        
        @media screen and (min-width: 768px) and (max-width:1200px) {
            .responsive-errorMessage {
                font-size: 10px;
                color: red;
                margin:0;
                /* padding:"3px"; */
                font-family: "HelveticaNeue-Medium",
            }
        }
        
        @media screen and (min-width: 1201px) {
            .responsive-errorMessage {
                font-size: 12px;
                color: red;
                margin:0;
                font-family: "HelveticaNeue-Medium",
            }
        }
